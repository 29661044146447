/************
1. General
************/

.C_container {
  max-width: 100vw;
  position: relative;
}

.C_container.with-min-height {
  min-height: 37.5vw;
}

@media screen and (max-width: 576px) {
  .C_container.with-min-height {
    min-height: 75vw;
  }
}

/************
1.1. ImageSlider
************/

/* Description */
.C_text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Position Text */
.C_numbertext {
  color: #f2f2f2;
  font-size: 24px;
  font-weight: 600;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
}

/* Full Description */
.C_full_description_container {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
}

.C_full_description {
  color: #f2f2f2;
  background-color: #ac252d;
  font-size: 2em;
  font-weight: bold;
  padding: 1vh 0;
  position: absolute;
  text-align: center;
  height: 5vh;
  overflow: hidden;
}

.C_full_description_top {
  top: 0;
  right: 0;
  width: 60%;
  height: 8vh;
}

.C_full_description_left {
  color: #333;
  background-color: #f2f2f2;
  bottom: 10vh;
  left: 0;
  width: 25%;
  height: 8vh;
}

.C_full_description_middle {
  bottom: 10vh;
  left: 25%;
  width: 50%;
  height: 8vh;
}

.C_full_description_right {
  color: #333;
  background-color: #f2f2f2;
  bottom: 10vh;
  right: 0;
  width: 25%;
  height: 8vh;
}

.C_full_description_bottom {
  color: #f2f2f2;
  background-color: #333;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 10vh;
  font-size: 2rem;
}



/************
1.2. MainImage
************/

.C_main_container {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.C_image {
  width: 100%;
}

.C_image_background {
  background-color: #666;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.C_image_background_show_property {
  /* Dimensions */
  width: 600px;
  height: 450px;
}

.C_image_background_full_screen {
  /* Dimensions */
  width: 99vw;
  height: 80vh;
}

.C_image_background_public_card_col_12 {
  width: 100%;
  height: 8vw;
}

.C_image_background_public_card_col_8 {
  width: 100%;
  height: 12vw;
}

.C_image_background_public_card_col_4 {
  width: 100%;
  height: 12vw;
}

.C_image_background_signage {
  /* Dimensions */
  width: 100vw;
  height: 100vh;
  background-color: #FFF;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.C_image_admin_show_property {
  /* Dimensions */
  width: 100%;
  height: 450px;
}

.C_image_admin_card_property {
  /* Dimensions */
  width: 100%;
  height: 300px;
}


/************
1.3. Arrows
************/

.C_prev, .C_next {
  background-color: #FFF;
  /* Float */
  position: absolute;
  top: 50%;
  /* Dimensions */
  width: 28px;
  height: 28px;
  /* Box */
  margin: -22px 0 0 8px;
  /* Make round */
  border-radius: 14px;
  /* Inner Text */
  font-size: 12px;
  text-align: center;
  line-height: 28px;
  overflow: hidden;
  color: #333 !important;
  font-weight: 600;
  /* Other */
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 100ms ease-out;
  user-select: none;
  z-index: 1000;
}
  
/* Position the "next button" to the right */
.C_next {
  right: 0;
  margin: -22px 8px 0 0;
}
  
/* On hover, add a black background color with a little bit see-through */
.C_prev:hover, .C_next:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
}


/************
1.4. Thumbs
************/

.C_thumbs_container {
  margin: 2vh 0 0 0;
}

/* Container for image text */
.C_row {
  margin: 0;
  background-color: 'transparent';
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
}

/* Six columns side by side */
.C_column {
  display: inline-block;
  float: none;
  width: 10%;
  margin: 0 6px;
}

/* Add a transparency effect for thumnbail images */
.C_thumb {
  opacity: 0.6;
  cursor: pointer;
}

.C_thumb_active,
.C_thumb:hover {
  opacity: 1;
}
  
.C_prev_thumb, .C_next_thumb {
  background-color: #FFF;
  /* Float */
  position: absolute;
  bottom: calc(9vh - 14px);
  /* Dimensions */
  width: 28px;
  height: 28px;
  /* Box */
  margin: 0 0 0 8px;
  /* Make round */
  border-radius: 14px;
  /* Inner Text */
  font-size: 12px;
  text-align: center;
  line-height: 28px;
  overflow: hidden;
  color: #333 !important;
  font-weight: 600;
  /* Other */
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 100ms ease-out;
  user-select: none;
  z-index: 1000;
}
  
/* Position the "next button" to the right */
.C_next_thumb {
  right: 0;
  margin: 0 8px 0 0;
}
  
/* On hover, add a black background color with a little bit see-through */
.C_prev_thumb:hover, .C_next:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
}


.C_image_background_show_property_thumb {
  /* Dimensions */
  height: 140px;
}

.C_image_background_full_screen_thumb {
  height: 18vh;
}

  /* Modal */
.C_modal {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(40,40,40,1);
}

.C_close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
  z-index: 2200;
}

.C_close:hover,
.C_close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

/************
1.4. Full Screen Controler
************/
.C_full_screen_button {
  background-color: #FFF;
  /* Float */
  position: absolute;
  bottom: 2%;
  right: 2%;
  /* Dimensions */
  width: 96px;
  height: 22px;
  /* Box */
  margin: -22px 0 0 8px;
  /* Make round */
  border-radius: 11px;
  /* Inner Text */
  font-size: 12px;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
  color: #333 !important;
  font-weight: 600;
  /* Other */
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 100ms ease-out;
  user-select: none;
  z-index: 1000;
}

.C_full_screen_button.left {
  left: 2%;
}

.C_modal .C_full_screen_button {
  width: 122px;
  bottom: 22%;
}

.C_modal .C_full_screen_button.C_full_screen_button_panoramic {
  width: 122px;
  bottom: 4% !important;
}


/************
2. Extra large devices (large desktops, 1200px and up)
************/
@media only screen and (max-width: 1600px) { 
  .C_image_admin_show_property {
    /* Dimensions */
    height: 300px;
  }
  .C_image_admin_card_property {
    /* Dimensions */
    height: 250px;
  }
  .C_column {
    width: 20%;
  }
  .C_image_background_public_card_col_12 {
    height: 9vw;
  }
  .C_image_background_public_card_col_8 {
    height: 12vw;
  }
  .C_image_background_public_card_col_4 {
    height: 12vw;
  }
}
@media only screen and (max-width: 1200px) { 
  .C_image_background_show_property {
    /* Dimensions */
    width: 450px;
    height: 300px;
  }
  .C_image_admin_card_property {
    /* Dimensions */
    height: 200px;
  }
  .C_image_admin_show_property {
    /* Dimensions */
    height: 250px;
  }
  .C_image_background_public_card_col_12 {
    height: 20vw;
  }
  .C_image_background_public_card_col_8 {
    height: 12vw;
  }
  .C_image_background_public_card_col_4 {
    height: 18vw;
  }
}

/************
3. Large devices (desktops, 992px and up)
************/
@media only screen and (max-width: 992px) {
  .C_image_background_show_property {
    /* Dimensions */
    width: 350px;
    height: 263px;
  }
  .C_image_admin_show_property {
    /* Dimensions */
    height: 200px;
  }
  .C_image_admin_card_property {
    /* Dimensions */
    height: 300px;
  }
  .C_column {
    width: 20%;
  }
  .C_image_background_public_card_col_12 {
    height: 27vw;
  }
  .C_image_background_public_card_col_8 {
    height: 18vw;
  }
  .C_image_background_public_card_col_4 {
    height: 24vw;
  }
}

/************
4. Medium devices (tablets, 768px and up)
************/
@media only screen and (max-width: 768px) {
  .C_image_background_show_property {
    /* Dimensions */
    width: 80vw;
    height: 60vw;
  }
  .C_image_admin_show_property {
    /* Dimensions */
    height: 450px;
  }
  .C_image_admin_card_property {
    /* Dimensions */
    height: 200px;
  }
  .C_column {
    width: 25%;
  }
  .C_image_background_public_card_col_12 {
    height: 27vw;
  }
  .C_image_background_public_card_col_8 {
    height: 35vw;
  }
  .C_image_background_public_card_col_4 {
    height: 38vw;
  }
}

/************
5. Small devices (landscape phones, 576px and up)
************/
@media only screen and (max-width: 576px) {
  .C_image_admin_show_property {
    /* Dimensions */
    height: 300px;
  }
  .C_image_admin_card_property {
    /* Dimensions */
    height: 250px;
  }
  .C_column {
    width: 33%;
  }
  .C_image_background_public_card_col_8 {
    height: 70vw;
  }
  .C_image_background_public_card_col_4 {
    height: 70vw;
  }
}
