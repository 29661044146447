@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');


.blacknovember {

    &__container {
        background-color: #242526;
    }

    &__title {
        color: #FFF;
        font-family: 'DM Sans', sans-serif;
        font-weight: bold;
        font-size: 4rem;
    }

    &__subtitle {
        margin: 2rem 0;
        font-size: 3rem;
        color: #fff;
        text-align: center;
        -webkit-animation: glow 1s ease-in-out infinite alternate;
        -moz-animation: glow 1s ease-in-out infinite alternate;
        animation: glow 1s ease-in-out infinite alternate;
    }

    &__info {
        font-size: 3rem;
        font-weight: bold;
        background: -webkit-linear-gradient(45deg, #b15c5c, #8f1e1e 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }

    &__topnav {
        position: sticky;
        width: 100%;
        height: 120px;
        background-color: transparent;
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    &__cards_container {
        margin: 2rem 0;
        min-height: 80vh;
    }

    &__button {
        margin: 6rem auto;
        background-color: #FFF;
        width: 25vw;
        border-radius: 5rem;
        font-size: 1.5rem;
        text-align: center;
        line-height: 0rem;
        overflow: hidden;
        color:  #242526;
        font-weight: 600;
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(#FFF, 0.2), 0 6px 20px 0 rgba(#FFF, 0.19);
        transition: all 100ms ease-out;
        user-select: none;
        z-index: 1000;
        padding: 2rem 2rem;
        @media only screen and (max-width: 767px) {
            width: 90vw;
            font-size: 2.5rem;
            padding: 4rem 2rem;
        }
    }
}


@-webkit-keyframes glow {
    from {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px  rgb(136, 125, 126), 0 0 30px  rgba(172,37,45,1), 0 0 40px  rgba(172,37,45,1), 0 0 50px  rgba(172,37,45,1), 0 0 60px  rgba(172,37,45,1);
    }
    to {
      text-shadow: 0 0 10px #fff, 0 0 20px #ff4da6, 0 0 30px rgb(181, 74, 80), 0 0 40px rgba(181,74,80,1), 0 0 50px rgba(181,74,80,1), 0 0 60px rgba(181,74,80,1), 0 0 70px rgba(181,74,80,1);
    }
}


.black_card {

    &__container {
        background-color: transparent;
        float: left;
        width: 100%;
        overflow: hidden;
        margin: 12px 6px;
        padding: 0;
        border-radius: 8px;
        box-shadow: 0 4px 12px 0 rgba(#888, 0.2), 0 6px 8px 0 rgba(#888, 0.19);
        font-family: 'DM Sans', sans-serif;
    }

    &__highlight {
        background: #D31027;  /* fallback for old browsers */
        background: rgba(172,37,45,1);
        background: linear-gradient(90deg, rgba(181,74,80,1) 0%, rgba(172,37,45,1) 100%);
        background: -webkit-linear-gradient(to right, rgba(181,74,80,1), rgba(172,37,45,1));  /* Chrome 10-25, Safari 5.1-6 */
        float: left;
        width: 90px;
        height: 30px;
        margin: -18px 10px 10px 10px;
        border-radius: 15px;
        font-size: 12px;
        text-align: center;
        line-height: 30px;
        overflow: hidden;
        color: #FFF;
        font-weight: 600;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        transition: all 100ms ease-out;
    }

    &__title {
        background-color: transparent;
        float: left;
        width: 100%;
        height: 36px;
        margin: 6px 0;
        padding: 0 8px;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        overflow: hidden;
        color: #FFF;
        font-weight: 600;
    }
    
    &__subtitle {
        background-color: transparent;
        float: left;
        width: 100%;
        height: 18px;
        margin: 6px 0;
        padding: 0 8px;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        overflow: hidden;
        color: #FFF;
        font-weight: 400;

        &--small_price {
            color: #D31027;
            text-decoration: line-through;
            font-size: 1.8rem;
            @media only screen and (max-width: 576px) {
                font-size: 2.2rem;
            }
        }
    }
    
    &__subtitle_bold {
        background-color: transparent;
        float: left;
        width: 100%;
        height: 36px;
        margin: 6px 0;
        padding: 0 8px;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        overflow: hidden;
        color: #EEE;
        font-weight: bold;
    }
    
    &__description {
        background-color: transparent;
        float: left;
        width: 100%;
        height: 100px;
        margin: 4px 0;
        padding: 0 8px;
        font-size: 12px;
        line-height: 14px;
        text-align: justify;
        overflow: hidden;
        color: #FFF;
        font-weight: 400;
    }

    &__bottomHighlight {
        background-color: #555;
        float: left;
        width: 100%;
        height: 42px;
        margin: 0;
        padding: 0 8px;
        font-size: 2rem;
        line-height: 42px;
        text-align: center;
        letter-spacing: 0.1em;
        overflow: hidden;
        color: #f8f8f8;
        font-weight: 600;
        @media only screen and (max-width: 1600px) {
            font-size: 1.8rem;
        }
        @media only screen and (max-width: 1300px) {
            font-size: 1.7rem;
        }
        @media only screen and (max-width: 576px) {
            font-size: 2.6rem;
        }
    }

}