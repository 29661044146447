@import "./normalize";
@import "./typography";
@charset "utf-8";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}


body {
  box-sizing: border-box;
  height: 100%
}

ul {
  list-style: none;
  margin-bottom: 0px;
}

a {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    color: #333;
  }

  &:hover {
    text-decoration: none;
    color: #333;
  }

  &:active,
  &:focus {
    text-decoration: none;
    color: #333;
  }
}

img {
  display: block;
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  border-radius: 3px;
}
